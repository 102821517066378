

.thead {
    font-size: 13px!important;
    color: #8a8a8a !important;
    font-weight: 600!important;
    font-family: 'Montserrat', serif!important;
    border-color: #c9c9c9 !important;
    padding: 0!important;
}

.theadinternaltableteams {
    font-size: 13px!important;
    color: #8a8a8a !important;
    font-weight: 600!important;
    font-family: 'Montserrat', serif!important;
    /* border: 1px solid #c9c9c9 !important;   */
    border: 1px solid #c9c9c9 !important;
    border-left: transparent !important;
    border-collapse: collapse;
    padding: 0!important;
}



.theadunassignedteams {
    font-size: 13px!important;
    color: #8a8a8a !important;
    font-weight: 600!important;
    font-family: 'Montserrat', serif!important;
    border-collapse: collapse;
    display: flex!important;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0!important;
}




.rowItem {
    font-size: 11px!important;
    color: #000000!important;
    font-weight: 500!important;
    font-family: 'Montserrat', serif!important;
    font-style: normal!important;
}


.noResults {
}

.noResultsText {
    background: transparent;
    color: #8a8a8a;
    border-radius: none;
    box-shadow: none;
    font-weight: 600;
    opacity: 100%;
    font-family: 'Montserrat', serif!important;
}

.rowItemBorder {
    border-bottom-color: #c9c9c9 !important;
}

.column1 {
    width: 4% !important;
}

.column2 {
    width: 19% !important;
}

.column3 {
    width: 67% !important;
}

.column4 {
    width: 10% !important;
}

.employeeCard {
    background: linear-gradient(180deg, #E6DE18 -55%, #438B44 127.5%), linear-gradient(180deg, #00B9F1 13.37%, #034EA2 104.38%), #C4C4C4;;
    border-radius: 5px;
    padding-block: 2px;
    padding-inline: 3px;
    cursor: grab;
    color: white;
    min-width: 170px;
    text-align: center;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}
