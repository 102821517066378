

.thead {
    font-size: 13px!important;
    color: #8a8a8a !important;
    font-weight: 600!important;
    font-family: 'Montserrat', serif!important;
    border-color: #c9c9c9 !important;
}



.rowItem {
    font-size: 11px!important;
    color: #000000!important;
    font-weight: 500!important;
    font-family: 'Montserrat', serif!important;
    font-style: normal!important;
    border-color: #c9c9c9 !important;
}


.container-label {
  position: relative;
  padding-left: 4.5%;
  cursor: pointer;
  color: #7b809a;
  display: flex;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-bottom: -10%;
}

.container-label-yes {
margin-left: '4%';
display: 'flex'; 
gap: 2; 
font-size: 13
}

.container-label-no {
margin-left: '10%'; 
display: 'flex';
gap: 5; 
font-size: 13
}

/* Hide the browser's default checkbox */
.container-label input {
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;
}


.checkmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 2.5%;
}

#yes-checkbox, #no-checkbox {
  border: 0.5px solid grey;
}

#yes-checkbox, #checkbox, #no-checkbox:checked {
  border: none;
}

/* When the checkbox is checked, add a blue background */
.container-label input[type='checkbox']:checked ~ .checkmark {
  background: rgba(136, 174, 50, 0.68);
}
