.thead {
    font-size: 15px !important;
    color: #8a8a8a !important;
    font-weight: 600 !important;
    font-family: 'Montserrat', serif !important;
    border-color: #c9c9c9 !important;
}

.rowItem {
    font-size: 13px !important;
    color: #000000 !important;
    font-weight: 500 !important;
    font-family: 'Montserrat', serif !important;
    font-style: normal !important;
    border-color: #c9c9c9 !important;
    vertical-align: baseline !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: green !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: lightgreen !important;
}

.MuiSwitch-track, .css-1jtflaz-MuiSwitch-track {
    background-color: green !important;
    border-color: green !important;
    border-top-color: rgb(66, 66, 74);
    border-right-color: rgb(66, 66, 74);
    border-bottom-color: rgb(66, 66, 74);
    border-left-color: rgb(66, 66, 74);
}